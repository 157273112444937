
import { Vue, Component } from 'vue-property-decorator';
import Join from '@/views/user/join.vue';

@Component({
  name: 'AppJoin',
  components: {
    Join,
  },
})
export default class extends Vue {
}
